import { useStaticQuery, graphql } from "gatsby"
import * as React from "react"
import { Carousel, CarouselItem, Col, Container, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"
import ContentCard from "../components/card"
import HeroVideo from "../components/hero-video"
import Slide from "../components/slide"
import Layout from "../layouts/layout"

interface DescriptionContentful {
  description: String
}

interface MediaContentful {
  url: String
}

interface CardContentful {
  title: String,
  description: DescriptionContentful,
  media: MediaContentful
}

interface GalleryContentful {
  quote: QuoteContentful,
  name: String,
  company: String
}

interface QuoteContentful {
  quote: String
}

const IndexPage = () => {
  const data = useStaticQuery(graphql`
        query IndexQuery {
            contentfulLayout(contentful_id: {eq: "T3H8XFGXUuivr4KGfj91v"}) {
                contentModule {
                    ... on ContentfulContentObject {
                        title
                        description {
                          description
                        }
                        link_url
                        link_text
                        media {
                          url
                        }
                        child_content_objects {
                          title
                          description {
                            description
                          }
                          media {
                            url
                          }
                        }
                    }
                    ... on ContentfulTestimonialGallery {
                      gallery_items {
                        quote {
                          quote
                        }
                        name
                        company
                      }
                    }
                }
                hero {
                  header_title
                  hero_body {
                    hero_body
                  }
                  cta_link
                  cta_copy
                  heroImage {
                    url
                  }
                }
            }
        }
    `)

  const page = data.contentfulLayout

  const heroVideoTitle    = page.hero.header_title
  const heroVideoBody     = page.hero.hero_body.hero_body
  const heroVideoBtnLink  = page.hero.cta_link
  const heroVideoBtnText  = page.hero.cta_copy
  const heroVideoSource   = page.hero.heroImage.url

  const videoHeader = page.contentModule[0].title
  const videoDesc   = page.contentModule[0].description.description
  const video       = page.contentModule[1].link_url

  const whyEdgeText = page.contentModule[2].title

  const indexCards: CardContentful[]  = page.contentModule[2].child_content_objects
  
  const learnMoreLink = page.contentModule[2].link_url
  const learnMoreText = page.contentModule[2].link_text

  const carouselBackground  = page.contentModule[3].media.url
  const carouselItems: GalleryContentful[] = page.contentModule[4].gallery_items

  return (
    <Layout>
      <Helmet>
          <title>Prospecting Solution for Retirement Plan Advisors | O3 Edge</title>
          <meta name="description" content="O3 Edge is an intuitive prospecting solution made to help retirement plan advisors expand their practices. Request a demo today." />
      </Helmet>

      <HeroVideo 
        title={heroVideoTitle} 
        body={heroVideoBody} 
        buttonLink={heroVideoBtnLink} 
        buttonText={heroVideoBtnText} 
        video={heroVideoSource} 
        />

      {/* Video */}
      <div className="my-5">
        <Container>
          <Col className="text-center">
            <h2 className="fw-bold text-primary">{videoHeader}</h2>
            <h3 className="fw-bold mb-5 text-primary">{videoDesc}</h3>
            <iframe className="video-iframe" src={video} allowFullScreen></iframe>
          </Col>
        </Container>
      </div>

      <Col className="text-center mt-5 mb-3">
        <h2 className="text-primary fw-bold">{whyEdgeText}</h2>
      </Col>

      <Container>
        <Row className="py-5 px-0 d-flex justify-content-center">
        {indexCards.map(card => 
          <Col key={card.title.toString()} size="12" md="4" className="px-3 text-center">
              <ContentCard header={card.title} image={card.media.url} text={card.description.description} />
          </Col>
          )}
        </Row>
      </Container>

      <p className="text-center">
        <a href={learnMoreLink} className="btn btn-secondary mb-5">{learnMoreText}</a>
      </p>

      <div className="o3-carousel" style={{ backgroundImage: `url(${carouselBackground})` }}>
        <Container>
          <Row>
            <Col className="px-0">
              <Carousel 
                indicators={false} 
                id="home-carousel" 
                className="slide text-white d-flex align-items-center"
                >
                {carouselItems.map(item =>
                  <CarouselItem key={item.company.toString()}>
                    <Slide 
                      company={item.company} 
                      name={item.name} 
                      quote={item.quote.quote} />
                  </CarouselItem>
                )}
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default IndexPage
