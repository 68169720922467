import React from 'react';

interface SlideProps {
    company: String,
    name: String,
    quote: String
}

const Slide = (props: SlideProps) => {
    return(
        <div className="carousel-slide px-3 py-5">
            <div className="text-center">
                <h3>{props.quote}</h3>
                <p>{props.name ? props.name + ",&nbsp;" : null}{props.company}</p>
            </div>
        </div>
    )
}

export default Slide;