import React from 'react';

interface CardProps {
    header: String,
    image: String,
    text: String
}

const ContentCard = (props: CardProps) => {
    return(
        <div className="why-card">
            <img src={props.image.toString()} className="mb-4" />
            <h4 className="text-primary fw-bold">{props.header}</h4>
            <h5>{props.text}</h5>
        </div>
    )
}

export default ContentCard;